<template>
    <span
        v-html="getSVG"
        class="inline-svg"
    ></span>
</template>
<script>
import __SVG from "../../assets/svgs.js";
export default {
    name: "inlinesvg",
    props: {
        src: { type: String, required: true },
    },
    computed: {
        getSVG() {
            return __SVG[this.src];
        },
    },
};
</script>

<style lang="less" scoped>
.inline-svg {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>